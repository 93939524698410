<template>
    <div
        class="route has-background-info-light has-text-centered"
        @click="goToRoute(route)"
    >
        <b-image
            :src="image"
            placeholder="/image/logo.png/"
            :alt="`Image for ${route.name}`"
            ratio="6by5"
        ></b-image>
        <p
            class="carbon-saving"
            @click.stop="favouriteOrLogin({
                route_id: route.id,
                is_favourite: !route.is_favourite,
            })"
            @mouseover="is_hover = true"
            @mouseleave="is_hover = false"
            :class="{
                'favourite-hover' : is_hover,
            }"
        >
            <b-icon
                v-if="show_favourite"
                :icon="route.is_favourite ? 'heart' : 'heart-outline'"
                size="is-small"
                class="mr-1 btn-favourite"
                :class="{
                    'has-text-primary' : route.is_favourite,
                }"
            ></b-icon>
            <span v-if="route.carbonsaving">{{route.carbonsaving}}% CO<sub>2</sub> saved</span>
        </p>
        <RatingStars
            v-if="show_rating"
            :detail="route"
            :floated="true"
            rating_type="rating"
        />
        <div class="content">
            <div class="route-name has-text-left">
                {{route.name}}
                <span class="route-subtitle">{{route.subtitle}}</span>
            </div>
            <span class="destinations" v-if="route.destinations">
                {{route.destinations.split(',').join(' - ')}}
            </span>
            <span class="destinations" v-else>
                press for details
            </span>
        </div>
    </div>
</template>

<script>
import {
    mapMutations,
    mapState,
    // mapGetters,
    mapActions,
} from 'vuex';

import RatingStars from '@/components/RatingStars.vue';

import router from '../router';

export default {
    name: 'RouteCard',
    components: {
        RatingStars,
    },
    props: {
        route: Object,
        show_favourite: {
            type: Boolean,
            default: true,
        },
        show_rating: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            is_hover: false,
        };
    },
    computed: {
        ...mapState({
            holiday_types: (state) => state.tag.holiday_types,
            is_logged_in: (state) => state.user.is_logged_in,
        }),
        image() {
            // set default image
            let route_image = '/image/logo.png/';
            // if we have legs, we have a route
            if (this.route.image) {
                // get the image
                route_image = this.route.image;
                const cloudinary_url_pattern = '^https://res.cloudinary.com/.*/image/upload/';
                const cloudinary_url_re = new RegExp(cloudinary_url_pattern, 'gi');
                const cloudinary_transforms_pattern = 'w_auto:100:900,dpr_auto,q_auto,f_auto';
                const cloudinary_transforms_re = new RegExp(cloudinary_transforms_pattern, 'gi');

                /**
                 * if it is a cloudinary image, make sure it has the correct overrides
                 */
                if (
                    route_image.match(cloudinary_url_re)
                    && !route_image.match(cloudinary_transforms_re)
                ) {
                    route_image = route_image.replace(/(upload\/)(v[0-9]{10})/, `$1${cloudinary_transforms_pattern}/$2`);
                }
            }
            return route_image;
        },
    },
    methods: {
        ...mapMutations('user', [
            'showLogin',
        ]),
        ...mapActions('route', [
            'toggleFavourite',
        ]),
        favouriteOrLogin({ route_id, is_favourite }) {
            if (this.is_logged_in) {
                this.$mixpanel.track('Route Card Toggle Favourite');
                this.toggleFavourite({ route_id, is_favourite });
            } else {
                this.showLogin();
            }
        },
        goToRoute(route) {
            // if user is logged in, go to route, otherwise load login
            this.$mixpanel.track(
                'Visit Route',
                {
                    id: route.id,
                    name: route.name,
                },
            );
            if (this.is_logged_in) {
                const route_params = {
                    name: 'Route',
                    params: { id: route.id, name: route.name },
                };
                this.$router.push(route_params);
            } else {
                router.push({
                    path: '/routes',
                    query: {
                        redirect: `/routes/${route.id}/${route.name}`,
                    },
                });
                this.showLogin();
            }
        },
    },
};
</script>

<style scoped lang="scss">
    .route {
        cursor: pointer;
        width:100%;
        margin: 1%;

        position: relative;
        display: flex;
        flex-direction: column;

        .carbon-saving {
            position: absolute;
            left: 0.5em;
            top: 0.5em;
            border-radius: 2em;
            background-color: $white;
            padding: 0.25em 0.5em;

            display: flex;
            align-items: center;

            &.favourite-hover {
                background-color: lighten($primary,20%);
            }

            span {
                font-size: 10px;
                font-weight: $font-medium;
            }
            sub {
                font-size: 8px;
                font-weight: $font-medium;
                vertical-align: baseline;
            }
        }

        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 0.5em;

            .route-name {
                font-weight: $font-bold;
                font-size: 14px;
                .route-subtitle {
                    font-weight: $font-semi-bold;
                    font-size: 14px;
                }
            }

            .destinations {
                display: inline-block;
                padding: 0.5em;
                margin-top: 0.5em;
                border-radius: 2em;
                background-color: $white;
                font-weight: $font-semi-bold;
                font-size: 10px;
                line-height: 12px;
            }
        }
    }

    @media screen and (min-width: $tablet) {
        .search {
            .route {
                width:48%;
            }
        }
    }

    @media screen and (min-width: $desktop) {
        .search {
            .route {
                width:31%;
            }
        }
    }

    @media screen and (min-width: $widescreen) {
        .search {
            .route {
                width:23%;
            }
        }
    }
</style>
