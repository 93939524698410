var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"route has-background-info-light has-text-centered",on:{"click":function($event){return _vm.goToRoute(_vm.route)}}},[_c('b-image',{attrs:{"src":_vm.image,"placeholder":"/image/logo.png/","alt":("Image for " + (_vm.route.name)),"ratio":"6by5"}}),_c('p',{staticClass:"carbon-saving",class:{
            'favourite-hover' : _vm.is_hover,
        },on:{"click":function($event){$event.stopPropagation();return _vm.favouriteOrLogin({
            route_id: _vm.route.id,
            is_favourite: !_vm.route.is_favourite,
        })},"mouseover":function($event){_vm.is_hover = true},"mouseleave":function($event){_vm.is_hover = false}}},[(_vm.show_favourite)?_c('b-icon',{staticClass:"mr-1 btn-favourite",class:{
                'has-text-primary' : _vm.route.is_favourite,
            },attrs:{"icon":_vm.route.is_favourite ? 'heart' : 'heart-outline',"size":"is-small"}}):_vm._e(),(_vm.route.carbonsaving)?_c('span',[_vm._v(_vm._s(_vm.route.carbonsaving)+"% CO"),_c('sub',[_vm._v("2")]),_vm._v(" saved")]):_vm._e()],1),(_vm.show_rating)?_c('RatingStars',{attrs:{"detail":_vm.route,"floated":true,"rating_type":"rating"}}):_vm._e(),_c('div',{staticClass:"content"},[_c('div',{staticClass:"route-name has-text-left"},[_vm._v(" "+_vm._s(_vm.route.name)+" "),_c('span',{staticClass:"route-subtitle"},[_vm._v(_vm._s(_vm.route.subtitle))])]),(_vm.route.destinations)?_c('span',{staticClass:"destinations"},[_vm._v(" "+_vm._s(_vm.route.destinations.split(',').join(' - '))+" ")]):_c('span',{staticClass:"destinations"},[_vm._v(" press for details ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }